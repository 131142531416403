// Rename folder Skeleton
// Rename const Skeleton
// Start edit index.js and styles.module.scss

import React from 'react';
import styles from './styles.module.scss';

const advisors = [
  {
    gender: 'male',
    name: 'Hung Nguyen',
    roles: [{ role: 'Vice Dean', org: 'SOICT, HUST', twoline: true }],
    img: '/advisors/hung-nguyen.jpeg',
  },
  {
    gender: 'male',
    name: 'Thu Hoang',
    roles: [
      {
        role: 'Dean, Faculty of Management & Tourism',
        org: 'HANU',
        twoline: true,
      },
    ],
    img: '/advisors/thu-hoang.jpg',
  },
  {
    gender: 'female',
    name: 'Trang Nguyen',
    roles: [
      {
        role: 'Partner Ecosystem Development Manager',
        org: 'KONE',
        twoline: false,
      },
      { role: 'Founder', org: 'JunctionX Hanoi', twoline: false },
      { role: 'Organizer', org: 'Junction & JunctionX Tokyo', twoline: false },
    ],
    img: '/advisors/trang-nguyen.png',
  },
  {
    gender: 'female',
    name: 'My Nguyen',
    roles: [
      {
        role: 'Co-Founder & CEO',
        org: 'GameFi.Org',
        twoline: false,
      },
      { role: 'VP', org: 'Icetea Labs', twoline: false },
      { role: 'Co-Founder', org: 'JunctionX Hanoi', twoline: false },
    ],
    img: '/advisors/my-nguyen.jpg',
  },
];

const teams = [
  [
    {
      name: 'Ha Nguyen',
      role: 'Founder & CEO',
      img: '/teams/ha-nguyen.jpeg',
    },
  ],
  [
    // HCMC Team
    {
      name: 'Linh Vu',
      role: 'Head of HCMC Team',
      img: '/teams/hcmc/linh-vu.jpeg',
    },
    // Partnership
    {
      name: 'Phuong Nguyen',
      role: 'Head of Partnership',
      img: '/teams/partnership/phuong.jpeg',
    },
    // Partnership
    {
      name: 'Uyen Tran',
      role: 'Head of Operation',
      img: '/teams/operation/uyen-tran.jpg',
    },
  ],

  [
    // Community
    {
      name: 'Chi Le',
      role: 'Head of Community',
      img: '/teams/community/linh-chi.jpg',
    },
    // Tech
    {
      name: 'Hiep Tran',
      role: 'Head of Tech',
      img: '/teams/tech/hiep-tran.jpg',
    },
    // People
    {
      name: 'Ly Vu',
      role: 'Head of People',
      img: '/teams/people/ly-vu.jpg',
    },
  ],

  [
    // Participant
    {
      name: 'Hang Nguyen',
      role: 'Head of Participant',
      img: '/teams/participant/hang-nguyen.jpg',
    },
    // Marketing
    {
      name: 'Dan Vu',
      role: 'Head of Marketing',
      img: '/teams/marketing/dan-vu.jpeg',
    },
    // Marketing
    {
      name: 'Nhi Hoang',
      role: 'Marketing Manager',
      img: '/teams/marketing/nhi-hoang.jpg',
    },
  ],
];

export const TeamAndAdvisor = () => (
  <>
    <main className={styles.main}>
      <div className={styles.HR}>
        <div className={styles.HR_header}>Our team</div>

        <div className={styles.HR_spacing} />

        <div className={styles.HR_description}>
          {teams.map((teamline, index) => (
            <TeamLine key={index} teamline={teamline} />
          ))}
        </div>
      </div>
    </main>

    {/* Advisors */}
    <main className={styles.main}>
      <div className={styles.HR}>
        <div className={styles.HR_spacing} />

        <div className={styles.HR_header}>Our advisors</div>

        <div className={styles.HR_spacing} />

        <div className={styles.advisors}>
          {advisors.map((advisor, index) => (
            <Advisor key={index} advisor={advisor} />
          ))}
        </div>

        <div className={styles.HR_spacing} />
      </div>
    </main>
  </>
);

export const TeamLine = ({ teamline }) => {
  const loadDefaultImage = name => e => {
    e.target.src = `https://ui-avatars.com/api/?&name=${name}`;
  };

  return (
    <div className={styles.teamline}>
      {teamline.map((member, index) => (
        <div className={styles.member} key={index}>
          <img
            className={styles.member_img}
            alt={`${member.name}-img`}
            src={member.img}
            onError={loadDefaultImage(member.name)}
          />
          <div className={styles.member_name}>{member.name}</div>
          <div className={styles.member_role}>{member.role}</div>
        </div>
      ))}
    </div>
  );
};

export const Advisor = ({ advisor }) => {
  const loadDefaultImage = name => e => {
    e.target.src = `https://ui-avatars.com/api/?&name=${name}`;
  };

  return (
    <div className={styles.advisor}>
      <img
        className={styles.advisor_img}
        alt={`${advisor.name}-img`}
        src={advisor.img}
        onError={loadDefaultImage(advisor.name)}
      />
      <div className={styles.advisor_name}>
        {advisor.gender == 'male' ? 'Mr. ' : 'Ms. '}
        {advisor.name}
      </div>
      <div className={styles.advisor_roles}>
        {advisor.roles.map((roleObject, index) => (
          <div className={styles.advisor_role} key={index}>
            {roleObject.role}, {roleObject.org}
          </div>
        ))}
      </div>
    </div>
  );
};
