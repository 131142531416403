// Rename folder Skeleton
// Rename const Skeleton
// Start edit index.js and styles.module.scss

import React from 'react';
import styles from './styles.module.scss';
import jxh_2_line from '../../images/Logo_Junction_Vietnam_horizontal-white.png';

const testimonials = [
  {
    name: 'vtc9',
    src: './testimonials/vtc9.png',
    link:
      'https://www.youtube.com/watch?v=GIlRiZaNF0M&ab_channel=JunctionXHanoi',
  },
  {
    name: 'ictnews',
    src: './testimonials/ictnews.png',
    link:
      'https://ictnews.vietnamnet.vn/cuoc-song-so/cuoc-thi-hackathon-quoc-te-khoi-nguon-cam-hung-cong-nghe-sang-tao-dam-me-403662.html',
  },
  {
    name: 'dantri',
    src: './testimonials/dantri.png',
    link:
      'https://dantri.com.vn/nhip-song-tre/cuoc-thi-hackathon-quoc-te-khoi-nguon-cam-hung-cong-nghe-sang-tao-dam-me-20220128161831350.htm',
  },
  {
    name: 'tienphong',
    src: './testimonials/tienphong.png',
    link:
      'https://tienphong.vn/viet-nam-lan-dau-to-chuc-cuoc-thi-phan-mem-lon-nhat-chau-au-post1064571.tpo',
  },
];

const socialMediaLinks = [
  {
    alt: 'facebook',
    logo: (
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16666 12.5003C4.16666 10.2902 5.04464 8.17057 6.60744 6.60777C8.17024 5.04497 10.2899 4.16699 12.5 4.16699H37.5C39.7101 4.16699 41.8298 5.04497 43.3926 6.60777C44.9554 8.17057 45.8333 10.2902 45.8333 12.5003V37.5003C45.8333 39.7105 44.9554 41.8301 43.3926 43.3929C41.8298 44.9557 39.7101 45.8337 37.5 45.8337H12.5C10.2899 45.8337 8.17024 44.9557 6.60744 43.3929C5.04464 41.8301 4.16666 39.7105 4.16666 37.5003V12.5003ZM12.5 8.33366C11.3949 8.33366 10.3351 8.77265 9.55372 9.55405C8.77232 10.3354 8.33333 11.3953 8.33333 12.5003V37.5003C8.33333 38.6054 8.77232 39.6652 9.55372 40.4466C10.3351 41.228 11.3949 41.667 12.5 41.667H25V27.0837H22.9167C22.3641 27.0837 21.8342 26.8642 21.4435 26.4735C21.0528 26.0828 20.8333 25.5529 20.8333 25.0003C20.8333 24.4478 21.0528 23.9179 21.4435 23.5272C21.8342 23.1365 22.3641 22.917 22.9167 22.917H25V19.792C25 17.8581 25.7682 16.0035 27.1357 14.636C28.5031 13.2686 30.3578 12.5003 32.2917 12.5003H33.5417C34.0942 12.5003 34.6241 12.7198 35.0148 13.1105C35.4055 13.5012 35.625 14.0311 35.625 14.5837C35.625 15.1362 35.4055 15.6661 35.0148 16.0568C34.6241 16.4475 34.0942 16.667 33.5417 16.667H32.2917C31.8813 16.667 31.4749 16.7478 31.0958 16.9049C30.7166 17.0619 30.3721 17.2921 30.082 17.5823C29.7918 17.8725 29.5616 18.217 29.4045 18.5961C29.2475 18.9753 29.1667 19.3816 29.1667 19.792V22.917H33.5417C34.0942 22.917 34.6241 23.1365 35.0148 23.5272C35.4055 23.9179 35.625 24.4478 35.625 25.0003C35.625 25.5529 35.4055 26.0828 35.0148 26.4735C34.6241 26.8642 34.0942 27.0837 33.5417 27.0837H29.1667V41.667H37.5C38.6051 41.667 39.6649 41.228 40.4463 40.4466C41.2277 39.6652 41.6667 38.6054 41.6667 37.5003V12.5003C41.6667 11.3953 41.2277 10.3354 40.4463 9.55405C39.6649 8.77265 38.6051 8.33366 37.5 8.33366H12.5Z"
          fill="white"
        />
      </svg>
    ),
    url: 'https://www.facebook.com/JunctionVietnam',
  },
  {
    alt: 'instagram',
    logo: (
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16667 12.5003C4.16667 10.2902 5.04465 8.17057 6.60745 6.60777C8.17025 5.04497 10.2899 4.16699 12.5 4.16699H37.5C39.7101 4.16699 41.8298 5.04497 43.3926 6.60777C44.9554 8.17057 45.8333 10.2902 45.8333 12.5003V37.5003C45.8333 39.7105 44.9554 41.8301 43.3926 43.3929C41.8298 44.9557 39.7101 45.8337 37.5 45.8337H12.5C10.2899 45.8337 8.17025 44.9557 6.60745 43.3929C5.04465 41.8301 4.16667 39.7105 4.16667 37.5003V12.5003ZM12.5 8.33366C11.3949 8.33366 10.3351 8.77265 9.55373 9.55405C8.77233 10.3354 8.33334 11.3953 8.33334 12.5003V37.5003C8.33334 38.6054 8.77233 39.6652 9.55373 40.4466C10.3351 41.228 11.3949 41.667 12.5 41.667H37.5C38.6051 41.667 39.6649 41.228 40.4463 40.4466C41.2277 39.6652 41.6667 38.6054 41.6667 37.5003V12.5003C41.6667 11.3953 41.2277 10.3354 40.4463 9.55405C39.6649 8.77265 38.6051 8.33366 37.5 8.33366H12.5ZM25 18.7503C23.3424 18.7503 21.7527 19.4088 20.5806 20.5809C19.4085 21.753 18.75 23.3427 18.75 25.0003C18.75 26.6579 19.4085 28.2476 20.5806 29.4197C21.7527 30.5918 23.3424 31.2503 25 31.2503C26.6576 31.2503 28.2473 30.5918 29.4194 29.4197C30.5915 28.2476 31.25 26.6579 31.25 25.0003C31.25 23.3427 30.5915 21.753 29.4194 20.5809C28.2473 19.4088 26.6576 18.7503 25 18.7503ZM14.5833 25.0003C14.5833 22.2377 15.6808 19.5881 17.6343 17.6346C19.5878 15.6811 22.2373 14.5837 25 14.5837C27.7627 14.5837 30.4122 15.6811 32.3657 17.6346C34.3192 19.5881 35.4167 22.2377 35.4167 25.0003C35.4167 27.763 34.3192 30.4125 32.3657 32.366C30.4122 34.3195 27.7627 35.417 25 35.417C22.2373 35.417 19.5878 34.3195 17.6343 32.366C15.6808 30.4125 14.5833 27.763 14.5833 25.0003ZM36.4583 16.667C37.2871 16.667 38.082 16.3378 38.668 15.7517C39.2541 15.1657 39.5833 14.3708 39.5833 13.542C39.5833 12.7132 39.2541 11.9183 38.668 11.3323C38.082 10.7462 37.2871 10.417 36.4583 10.417C35.6295 10.417 34.8347 10.7462 34.2486 11.3323C33.6626 11.9183 33.3333 12.7132 33.3333 13.542C33.3333 14.3708 33.6626 15.1657 34.2486 15.7517C34.8347 16.3378 35.6295 16.667 36.4583 16.667Z"
          fill="white"
        />
      </svg>
    ),
    url: 'https://www.instagram.com/junction_vietnam/',
  },
  {
    alt: 'youtube',
    logo: (
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3438 9.94805C21.7438 9.18346 28.2563 9.18346 33.6563 9.94805C38.5625 10.6439 42.3646 14.0855 43.0896 17.7939C43.9708 22.2835 43.9708 27.7168 43.0896 32.2064C42.3625 35.9147 38.5625 39.3564 33.6563 40.0522C28.2563 40.8168 21.7417 40.8168 16.3438 40.0522C11.4375 39.3564 7.63751 35.9147 6.91042 32.2064C6.02917 27.7168 6.02917 22.2835 6.91042 17.7939C7.63751 14.0855 11.4375 10.6439 16.3438 9.94805ZM34.2396 5.82305C28.4542 5.0043 21.5458 5.0043 15.7604 5.82305C9.47918 6.71263 3.95834 11.1939 2.82292 16.9939C1.83751 22.0126 1.83751 27.9876 2.82292 33.0064C3.95834 38.8064 9.47918 43.2876 15.7604 44.1772C21.5458 44.996 28.4542 44.996 34.2396 44.1772C40.5208 43.2876 46.0417 38.8064 47.1771 33.0064C48.1625 27.9876 48.1625 22.0126 47.1771 16.9939C46.0417 11.1939 40.5208 6.71263 34.2396 5.82305ZM21.9896 14.9335C21.6758 14.7241 21.3111 14.6039 20.9344 14.5856C20.5577 14.5673 20.1831 14.6516 19.8505 14.8296C19.5179 15.0075 19.2399 15.2724 19.0461 15.5959C18.8523 15.9195 18.75 16.2896 18.75 16.6668V33.3335C18.75 33.7106 18.8523 34.0808 19.0461 34.4043C19.2399 34.7279 19.5179 34.9928 19.8505 35.1707C20.1831 35.3486 20.5577 35.433 20.9344 35.4147C21.3111 35.3964 21.6758 35.2761 21.9896 35.0668L34.4896 26.7335C34.7749 26.5432 35.0089 26.2855 35.1707 25.9831C35.3325 25.6807 35.4171 25.3431 35.4171 25.0001C35.4171 24.6572 35.3325 24.3195 35.1707 24.0172C35.0089 23.7148 34.7749 23.4571 34.4896 23.2668L21.9896 14.9335ZM29.5771 25.0001L22.9167 29.4418V20.5605L29.5771 25.0001Z"
          fill="white"
        />
      </svg>
    ),
    url: 'https://www.youtube.com/channel/UCs2nzuDhDwtJ9TdgqMB3TTA',
  },
];

export const Footer = () => (
  <main className={styles.main}>
    {/* <div className={styles.spacing} />

    <content>
      <div className={styles.join_us}>
        <h2>Join us!</h2>
        <div id={styles.join_partner}>As partner</div>
        <div id={styles.join_volunteer}>As volunteer</div>
      </div>
    </content> */}

    <div className={styles.spacing} />

    <div className={styles.testimonial}>
      <div className={styles.left_col}>
        <h1>Stories about us</h1>
        <p>{`Here are what people say about us!`}</p>
      </div>
      <div className={styles.right_col}>
        <div className={styles.prev_partners}>
          {testimonials.map((testimonial, index) => (
            <img
              onClick={() => (window.location = testimonial.link)}
              key={index}
              src={testimonial.src}
              className={styles.prev_partner}
            />
          ))}
        </div>
      </div>
    </div>

    {/* <div className={styles.spacing} />

    <div className={styles.testimonial}>
      <div className={styles.left_col}>
        <h1>Stay up to date</h1>
        <p>{`Subscribe to our newsletter and get updates about what's happening in Junction Vietnam community.`}</p>
      </div>
      <div className={styles.right_col}>
        <div className={styles.subscription}>
          <input placeholder={`Enter you email here`} />
          <div className={styles.submit}>Subscribe</div>
        </div>
      </div>
    </div> */}

    <div className={styles.spacing} />
    <div className={styles.spacing} />

    <div className={styles.footer_jxh}>
      <img src={jxh_2_line} width="100px" height="30px" />
      <div style={{ width: '0px', height: '20px' }}></div>
      <p>We empower people to create with technology.</p>
      <div style={{ width: '0px', height: '20px' }}></div>
      <a href="mailto:ha.nguyen@hackjunction.com">ha.nguyen@hackjunction.com</a>
      <div style={{ width: '0px', height: '20px' }}></div>
      <div className={styles.links}>
        {socialMediaLinks.map(link => (
          <a key={link.alt} alt={link.alt} href={link.url}>
            {link.logo}
          </a>
        ))}
      </div>

      <div className={styles.spacing} />

      <p className={styles.bottomline}>
        Design and developed with 💖 by Junction Vietnam
      </p>
      <div style={{ width: '0px', height: '20px' }} />
    </div>
  </main>
);
