import React from 'react';
import styles from './styles.module.scss';
import banner_image from '../../images/banner01.png';
import logo1line from '../../images/Logo_Junction_Vietnam_horizontal-white.png';
import { navigate } from 'gatsby';
export const Banner = () => (
  <main className={styles.main}>
    <img className={styles.banner_img} src={banner_image} />
    <div className={styles.overlay}></div>
    <content>
      <div style={{ height: '35vh' }} />
      <img src={logo1line} />
      <div style={{ height: '5vh' }} />
      <h2>Hack the Future</h2>
      <div className={styles.spacing} />
      {/* <button
        onClick={() => navigate('https://junctionvietnam2022.com/')}
        className={styles.cta_primary}
      >
        HACKATHON 2022
      </button> */}
      <div style={{ height: '2vh' }} />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={'https://forms.gle/QrY9NjGMHSwwmJnm6'}
        className={styles.cta_secondary}
      >
        Partnership Opportunity
      </a>
    </content>
  </main>
);
