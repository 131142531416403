import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import { navlinks } from '../../constants/menu-items';
import './navbar.scss';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

const Navbar = () => {
  const { width } = useWindowDimensions();

  const isMoble = width <= 768;

  const NavLink = ({ icon, title, toElement }) => (
    <Link
      className="menu__li"
      activeClass="menu__li_selected"
      to={toElement}
      spy={true}
      smooth={true}
      offset={-50}
    >
      {isMoble ? <span>{icon}</span> : <span>{title}</span>}
    </Link>
  );

  return (
    <nav className="menu__nav">
      <ul className="menu__ul pointer">
        {navlinks.map(({ icon, title, toElement }, index) => (
          <NavLink
            icon={icon}
            title={title}
            toElement={toElement}
            key={index}
          />
        ))}
      </ul>
    </nav>
  );
};

function getWindowDimensions() {
  if (isBrowser) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  return {
    width: 0,
    height: 0,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export { Navbar };
