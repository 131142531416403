// Rename folder Skeleton
// Rename const Skeleton
// Start edit index.js and styles.module.scss

import React from 'react';
import styles from './styles.module.scss';

const partners = [
  { name: 'US Embassy', src: './partners/embassy_usa.jpeg', link: '' },
  { name: 'Finland Embassy', src: './partners/embassy_finland.png', link: '' },
  { name: 'Hanu', src: './partners/DHHN.jpeg', link: '' },
  { name: 'Hutech', src: './partners/hutech.png', link: '' },
  { name: 'Viettel', src: './partners/viettel.png', link: '' },
  { name: 'FPT', src: './partners/fpt.png', link: '' },
  { name: 'Airmeet', src: './partners/airmeet.png', link: '' },
  { name: 'VNPT', src: './partners/vnpt.jpg', link: '' },
  {
    name: 'congdongtrituenhantao',
    src: './partners/trituenhantao.png',
    link: 'https://trituenhantao.io/',
  },
];

export const Partners = () => (
         <>
           <main className={styles.main}>
             <div className={styles.partner}>
               <div className={styles.partner_header}>Why partner with us?</div>

               <div className={styles.partner_spacing} />

               <content>
                 <div className={styles.fixed_left_col}>
                   <h2>{`RECRUITMENT`}</h2>
                 </div>
                 <div className={styles.space_col} />
                 <div className={styles.right_col}>
                   <p>
                     {`Unique recruiting opportunities through observation of the real-time product creation and outreach to a large pool of excellent candidates, with diverse skills and expertise.`}
                   </p>
                 </div>
               </content>

               <content>
                 <div className={styles.fixed_left_col}>
                   <h2>{`BRAND RECOGNITION`}</h2>
                 </div>
                 <div className={styles.space_col} />
                 <div className={styles.right_col}>
                   <p>
                     {`Drastically boost your brand awareness amongst top tech talents, communities, other companies and the media.`}
                   </p>
                 </div>
               </content>

               <content>
                 <div className={styles.fixed_left_col}>
                   <h2>{`PRODUCT PROMOTION & FEEDBACK`}</h2>
                 </div>
                 <div className={styles.space_col} />
                 <div className={styles.right_col}>
                   <p>
                     {`A rare opportunity to promote and run experiment for products/ services as well as receiving feedback from your potential consumers.`}
                   </p>
                 </div>
               </content>

               <content>
                 <div className={styles.fixed_left_col}>
                   <h2>{`CREATIVITY & COLLABORATION`}</h2>
                 </div>
                 <div className={styles.space_col} />
                 <div className={styles.right_col}>
                   <p>
                     {`The best way to take some refreshment by capturing various innovative ideas from the competing teams throughout 48 hours of fast prototyping to solve technology problems.`}
                   </p>
                 </div>
               </content>
             </div>
           </main>
           <main className={styles.main}>
             <div className={styles.spacing} />
             <div className={styles.partner}>
               <content className="alignStart">
                 <div className={styles.left_col}>
                   <div className={styles.left_block}>
                     <div className={styles.left_block_title}>
                       Some of our previous partners
                     </div>
                     <div className={styles.left_block_description}>
                       Our partners are a mix of industry leaders and agile
                       startups, looking to support aspiring developers and
                       co-create the innovations of tomorrow.
                     </div>
                   </div>
                 </div>
                 <div className={styles.space_col} />
                 <div className={styles.right_col}>
                   <div className={styles.prev_partners}>
                     {partners.map((p, i) => (
                       <img
                         src={p.src}
                         key={i}
                         className={styles.prev_partner}
                         onClick={() => (window.location = p.link)}
                       />
                     ))}
                   </div>
                 </div>
               </content>
             </div>
           </main>
         </>
       );
