import React from 'react';
import { DiGithubBadge } from 'react-icons/di';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';

export const navlinks = [
  {
    title: 'Home',
    toElement: 'home',
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M29.1907 13.0486C29.19 13.0479 29.1893 13.0472 29.1886 13.0465L16.951 0.809326C16.4294 0.287476 15.7359 0 14.9982 0C14.2605 0 13.567 0.287247 13.0451 0.809097L0.813904 13.0401C0.809784 13.0442 0.805664 13.0486 0.801544 13.0527C-0.269623 14.13 -0.267791 15.878 0.806809 16.9526C1.29776 17.4438 1.94618 17.7283 2.63947 17.758C2.66762 17.7608 2.696 17.7621 2.72461 17.7621H3.21236V26.768C3.21236 28.55 4.66232 30 6.44486 30H11.2326C11.7178 30 12.1115 29.6066 12.1115 29.1211V22.0605C12.1115 21.2473 12.773 20.5859 13.5862 20.5859H16.4101C17.2234 20.5859 17.8848 21.2473 17.8848 22.0605V29.1211C17.8848 29.6066 18.2783 30 18.7637 30H23.5515C25.334 30 26.784 28.55 26.784 26.768V17.7621H27.2363C27.9737 17.7621 28.6672 17.4749 29.1893 16.953C30.265 15.8766 30.2655 14.1257 29.1907 13.0486V13.0486Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'About us',
    toElement: 'about-us',
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0003 0C7.76369 0 1.89807 5.86562 1.89807 13.1023C1.89807 18.0528 4.64221 22.3556 8.69029 24.5851L8.5362 30L14.7184 26.1899C14.8117 26.192 14.905 26.2045 15.0003 26.2045C22.237 26.2045 28.1026 20.3389 28.1026 13.1023C28.1026 5.86562 22.237 0 15.0003 0ZM16.669 20.7372H13.3117V9.93047H16.669V20.7372ZM14.9678 8.60662C13.906 8.60662 13.1996 7.85402 13.2226 6.92533C13.2006 5.95367 13.906 5.22309 14.9899 5.22309C16.0726 5.22309 16.7602 5.95367 16.7822 6.92533C16.7812 7.85402 16.0705 8.60662 14.9678 8.60662Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    title: 'Our team & Advisor',
    toElement: 'our-team-and-advisor',
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6693 16.0149H23.3492C23.5857 16.6624 23.7149 17.3611 23.7149 18.0893V26.8579C23.7149 27.1616 23.6621 27.453 23.5659 27.7241H27.4015C28.8343 27.7241 29.9999 26.5584 29.9999 25.1256V20.3455C30 17.9576 28.0573 16.0149 25.6693 16.0149Z"
          fill="white"
        />
        <path
          d="M6.28507 18.0893C6.28507 17.361 6.41427 16.6624 6.65076 16.0149H4.33067C1.94274 16.0149 0 17.9576 0 20.3456V25.1257C0 26.5584 1.16561 27.7241 2.5984 27.7241H6.43408C6.33792 27.4529 6.28507 27.1616 6.28507 26.8579V18.0893V18.0893Z"
          fill="white"
        />
        <path
          d="M17.652 13.7588H12.348C9.96007 13.7588 8.01733 15.7015 8.01733 18.0895V26.858C8.01733 27.3363 8.40511 27.7242 8.88347 27.7242H21.1166C21.5949 27.7242 21.9827 27.3364 21.9827 26.858V18.0895C21.9827 15.7015 20.04 13.7588 17.652 13.7588Z"
          fill="white"
        />
        <path
          d="M15 2.27576C12.1282 2.27576 9.79187 4.61213 9.79187 7.48399C9.79187 9.43194 10.867 11.1332 12.4548 12.0264C13.2079 12.4501 14.0761 12.6922 15 12.6922C15.924 12.6922 16.7922 12.4501 17.5453 12.0264C19.1331 11.1332 20.2082 9.43188 20.2082 7.48399C20.2082 4.61219 17.8718 2.27576 15 2.27576Z"
          fill="white"
        />
        <path
          d="M5.85462 7.13025C3.70686 7.13025 1.95959 8.87752 1.95959 11.0253C1.95959 13.173 3.70686 14.9203 5.85462 14.9203C6.39943 14.9203 6.91822 14.8075 7.38949 14.6046C8.20429 14.2538 8.87613 13.6328 9.29186 12.855C9.58365 12.3091 9.74965 11.6863 9.74965 11.0253C9.74965 8.87758 8.00238 7.13025 5.85462 7.13025Z"
          fill="white"
        />
        <path
          d="M24.1454 7.13025C21.9976 7.13025 20.2504 8.87752 20.2504 11.0253C20.2504 11.6863 20.4164 12.3091 20.7082 12.855C21.1239 13.6329 21.7957 14.2539 22.6105 14.6046C23.0818 14.8075 23.6006 14.9203 24.1454 14.9203C26.2932 14.9203 28.0404 13.173 28.0404 11.0253C28.0404 8.87752 26.2932 7.13025 24.1454 7.13025Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    title: 'Our Partner',
    toElement: 'our-partner',
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M29.8791 11.8601L24.547 2.77441C24.3025 2.35787 23.7677 2.2166 23.3495 2.45812L20.154 4.30307L17.2756 3.24434C17.1697 3.20537 17.0624 3.17268 16.9543 3.14519C16.1439 2.93918 15.2848 3.05478 14.5518 3.47801L9.79382 6.22506C8.74288 6.8318 8.38282 8.17564 8.98956 9.22658C9.5963 10.2775 10.9401 10.6376 11.9911 10.0308L17.2122 7.01637C17.7561 6.70236 18.4261 6.70236 18.97 7.01637C18.97 7.01637 29.4892 13.0917 29.5229 13.1125L29.6797 12.9835C30.0132 12.7089 30.0977 12.2327 29.8791 11.8601V11.8601Z"
            fill="white"
          />
          <path
            d="M28.5424 14.5727L18.0911 8.53868L12.87 11.5531C12.2606 11.905 11.5842 12.0847 10.8989 12.0847C10.5553 12.0847 10.2095 12.0395 9.86881 11.9483C8.84835 11.6748 7.9954 11.0204 7.46723 10.1054C6.93901 9.1906 6.79874 8.12472 7.07219 7.10425C7.2979 6.26179 7.78387 5.53411 8.46116 5.00847L6.3062 3.76429C5.87653 3.5162 5.32674 3.67276 5.09219 4.10999L0.104518 13.4089C-0.119837 13.8272 0.0284633 14.3482 0.439616 14.5855L0.896471 14.8492L1.69833 13.4603C2.36354 12.3081 3.6038 11.5923 4.93505 11.5923C5.58825 11.5923 6.23348 11.7654 6.80091 12.0931C7.12733 12.2815 7.41848 12.5138 7.6685 12.7817C8.07262 12.6342 8.50428 12.5549 8.9462 12.5549C9.5994 12.5549 10.2446 12.7281 10.8121 13.0557C11.6761 13.5545 12.2942 14.3601 12.5524 15.3238C12.5808 15.4298 12.6036 15.5364 12.6226 15.6431C12.982 15.7261 13.3292 15.8622 13.6513 16.0482C14.5154 16.547 15.1335 17.3525 15.3917 18.3163C15.4201 18.4223 15.4429 18.5288 15.4619 18.6356C15.8213 18.7186 16.1684 18.8546 16.4905 19.0406C18.2742 20.0705 18.8876 22.3595 17.8578 24.1431L16.7629 26.0396L19.1099 27.3946C20.0557 27.9407 21.2651 27.6166 21.8112 26.6708C22.3573 25.7249 22.0332 24.5155 21.0874 23.9694L22.6097 24.8483C23.5556 25.3944 24.765 25.0703 25.3111 24.1245C25.8572 23.1787 25.5331 21.9692 24.5873 21.4231L25.6022 22.009C26.548 22.5551 27.7575 22.231 28.3035 21.2852C28.8496 20.3393 28.5255 19.1299 27.5797 18.5838L26.5648 17.9979C27.5106 18.544 28.7201 18.2199 29.2662 17.274C29.8123 16.3282 29.4882 15.1188 28.5424 14.5727V14.5727Z"
            fill="white"
          />
          <path
            d="M15.6117 20.563C14.6658 20.0169 13.4564 20.341 12.9103 21.2868L13.4962 20.272C14.0423 19.3262 13.7183 18.1167 12.7724 17.5706C11.8266 17.0245 10.6172 17.3486 10.0711 18.2944L10.657 17.2795C11.2031 16.3337 10.879 15.1243 9.93322 14.5782C8.9874 14.0321 7.77797 14.3562 7.23187 15.302L6.64593 16.3168C7.19197 15.371 6.86795 14.1616 5.92207 13.6155C4.97625 13.0694 3.76681 13.3935 3.22072 14.3393L1.0967 18.0182C0.550603 18.964 0.874685 20.1735 1.82051 20.7196C2.76633 21.2657 3.97582 20.9416 4.52185 19.9957C3.97576 20.9415 4.29984 22.151 5.24566 22.6971C6.19148 23.2431 7.40097 22.9191 7.94701 21.9732C7.40092 22.919 7.725 24.1285 8.67088 24.6745C9.6167 25.2206 10.8261 24.8965 11.3722 23.9507C10.8261 24.8965 11.1502 26.106 12.096 26.652C13.0419 27.1981 14.2513 26.874 14.7974 25.9282L16.3355 23.2641C16.8816 22.3185 16.5575 21.109 15.6117 20.563V20.563Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export const mainMenuItems = [
  {
    path: '/',
    title: 'home',
  },
  {
    path: '/about',
    title: 'about',
  },
  {
    path: '/packages',
    title: 'packages',
  },
  {
    path: '/contact',
    title: 'contact',
  },
];

export const socialMenuItems = [
  {
    icon: <FaLinkedin />,
    url: 'https://www.linkedin.com/in/morgan-baker-developer-inverness',
    name: 'LinkedIn',
  },
  {
    icon: <FaTwitter />,
    url: 'https://www.twitter.com',
    name: 'Twitter',
  },
  {
    icon: <DiGithubBadge />,
    url: 'https://github.com/bagseye',
    name: 'GitHub',
  },
];

// export const footerMenuItems = [
//   {
//     path: "/privacy",
//     title: "privacy",
//   },
//   {
//     path: "/cookies",
//     title: "cookies",
//   },
// ]
