import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const events = [
  {
    id: 0,
    name: '',
    image: '/events/event_chatbot_with_nlp.jpg',
    month: 'December',
    year: '2019',
    url: '/blog/build-your-own-chatbot-with-NLP-workshop',
  },
  {
    id: 1,
    name: '',
    image: '/events/event_cyber_security.jpg',
    month: 'Feburary',
    year: '2020',
    url: 'https://www.facebook.com/events/204579007327996',
  },
  {
    id: 2,
    name: '',
    image: '/events/event_microservice.jpg',
    month: 'April',
    year: '2020',
    url: 'https://www.facebook.com/events/2580391398874497',
  },
  {
    id: 3,
    name: '',
    image: '/events/event_ui_ux.jpg',
    month: 'May',
    year: '2020',
    url: 'https://www.facebook.com/events/616137872319371',
  },
  // {
  //   id: 4,
  //   name: '',
  //   image: '/events/event_junctionxasia.jpg',
  //   month: 'June',
  //   year: '2020',
  //   url: 'https://asia.hackjunction.com',
  // },
  {
    id: 5,
    name: '',
    image: '/events/event_api_security.jpg',
    month: 'July',
    year: '2020',
    url: 'https://www.facebook.com/events/2971548929640453',
  },
];

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 320px; */
  /* height: 168px; */
`;

const Image = styled.img`
  max-width: 250px;
  /* width: 25vw; */
  height: 130px;
  object-fit: cover;
  margin: 10;
  overflow: hidden;
  border-radius: 1rem;
  margin: auto;
`;

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {events.reverse().map(event => (
        <ImageWrapper key={event.id}>
          <Image alt={event.name} src={event.image} />
        </ImageWrapper>
      ))}
    </Slider>
  );
}
