// Rename folder Skeleton
// Rename const Skeleton
// Start edit index.js and styles.module.scss

import React from 'react';
import SimpleSlider from './SimpleSlider';
import styles from './styles.module.scss';

export const Intro = () => (
  <>
    <main className={styles.main}>
      <content>
        <div className={styles.left_col}>
          <h1>Empowering people to create with technology</h1>
        </div>
        <div className={styles.right_col}>
          <p>
            {`We are Junction Vietnam – a non-profit and multidisciplinary tech
          community in Vietnam embracing the goal of bridging the gap between
          creators by making everyone fall in love with technology. We believe
          that technology is a powerful tool when used right, and everybody
          should have the possibility to build meaningful things with it: that's
          why all of our events are centered around learning and creating with
          technology.`}
          </p>
        </div>
      </content>

      <div className={styles.spacing} />

      <content>
        <div className={styles.left_col}>
          <h1>What we do</h1>
        </div>
        <div className={styles.right_col}>
          <p>
            Our volunteer-lead teams organize hackathons, speaker events, coding
            workshops and other tech events on a global scale throughout the
            year.
          </p>
        </div>
      </content>
    </main>

    {/* JUNCTIONX EVENTS */}
    <main className={styles.main}>
      <div className={styles.event}>
        <div className={styles.event_header}>JunctionX Hanoi 2021</div>

        <div className={styles.event_spacing} />

        <p className={styles.event_description}>
          JunctionX Hanoi 2021 is an online hackathon organized by JunctionX
          Hanoi. Targeted at solving problems we are facing during this ongoing
          pandemic, we bring together developers, designers and other
          tech-minded individuals to create projects and solve intriguing
          challenges. During the this difficult time of Covid-19, we realize
          that our online event could contribute to the regional resolution by
          raising awareness and inspire people to build solution to tackle.
        </p>

        <div className={styles.event_spacing} />

        <div className={styles.blocks}>
          <div className={styles.block}>
            <figure>04</figure>
            <title>tracks</title>
          </div>
          <div className={styles.block}>
            <figure>30+</figure>
            <title>projects</title>
          </div>
          <div className={styles.block}>
            <figure>40+</figure>
            <title>partners</title>
          </div>
          <div className={styles.block}>
            <figure>300+</figure>
            <title>participants</title>
          </div>
        </div>
      </div>
    </main>

    <main className={styles.main}>
      <div className={styles.event}>
        <div className={styles.event_header}>JunctionX Asia 2020</div>

        <div className={styles.event_spacing} />

        <p className={styles.event_description}>
          JunctionX Asia is an online hackathon targeted at solving problems the
          global community is facing during this ongoing pandemic. Our goal lies
          in bringing developers, designers and other tech-minded individuals
          together to create exciting projects and solve intriguing challenges.
        </p>

        <div className={styles.event_spacing} />

        <div className={styles.blocks}>
          <div className={styles.block}>
            <figure>04</figure>
            <title>tracks</title>
          </div>
          <div className={styles.block}>
            <figure>80+</figure>
            <title>projects</title>
          </div>
          <div className={styles.block}>
            <figure>40+</figure>
            <title>partners</title>
          </div>
          <div className={styles.block}>
            <figure>500+</figure>
            <title>participants</title>
          </div>
        </div>
      </div>
    </main>

    <main className={styles.main}>
      <div className={styles.event}>
        <div className={styles.event_header}>JunctionX Hanoi 2018</div>

        <div className={styles.event_spacing} />

        <p className={styles.event_description}>
          Started out from 2018 as part of a bigger global movement, Junction -
          the leading international hackathon and tech community in Europe,
          JunctionX Hanoi has brought a new color to hackathon in Vietnam, with
          the record of biggest number of participants, coming from all fields
          and having English as the main language at the event. The message came
          across was that coding is FUN, and technology is for EVERYONE.
        </p>

        <div className={styles.event_spacing} />

        <div className={styles.blocks}>
          <div className={styles.block}>
            <figure>03</figure>
            <title>tracks</title>
          </div>
          <div className={styles.block}>
            <figure>40+</figure>
            <title>projects</title>
          </div>
          <div className={styles.block}>
            <figure>30+</figure>
            <title>partners</title>
          </div>
          <div className={styles.block}>
            <figure>260+</figure>
            <title>participants</title>
          </div>
        </div>
      </div>
    </main>

    <main className={styles.main}>
      <div className={styles.event}>
        <div className={styles.event_header}>SISU Talks</div>

        <div className={styles.event_spacing} />

        <p className={styles.event_description}>
          Organized on the last Sunday of each month in Hanoi, SISU Talk brings
          people together to meet, learn and network in an evening of the latest
          research, keynotes, demos and discussions. SISU Talk crowd consists of
          a community of curious minds, who want to explore the most relevant
          developments in tech.
        </p>

        <div className={styles.event_spacing} />

        <div className={styles.event_slider}>
          <SimpleSlider />
        </div>
        <div className={styles.event_spacing} />
      </div>
    </main>
  </>
);
