import React from 'react';
import { Element } from 'react-scroll';
import { Banner } from '../components/Banner';
import { Footer } from '../components/Footer';
import { Intro } from '../components/Intro';
import { Navbar } from '../components/Navbar';
import { Partners } from '../components/Partners';
import { TeamAndAdvisor } from '../components/TeamAndAdvisor';

const Home = () => (
  <>
    <Navbar />
    <Element name="home">
      <Banner />
    </Element>
    <Element name="about-us">
      <Intro />
    </Element>
    <Element name="our-team-and-advisor">
      <TeamAndAdvisor />
    </Element>
    <Element name="our-partner">
      <Partners />
    </Element>
    <Element name="contacts">
      <Footer />
    </Element>
  </>
);

export default Home;
